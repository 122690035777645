import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    maxItems: { type: Number, default: 1 },
    placeholder: String,
  };

  connect() {
    this.initializeTomSelect();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.element) return;

    this.select = new TomSelect(this.element, {
      maxItems: this.maxItemsValue,
      selectOnTab: true,
      closeAfterSelect: true,
      hidePlaceholder: true,
      placeholder: this.placeholderValue || "",
      create: false,
      openOnFocus: true,
      highlight: true,
    });
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
