import React from "react";

export function EditableInputCell({ getValue, row, column, table, inputType }) {
  const initialValue = getValue();
  const [value, setValue] = React.useState<string | Date | null>(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      inputType == "date" ? new Date(e.target.value) : e.target.value;

    setValue(newValue);
    table.options.meta?.updateData(row.index, column.id, newValue); // Send the new value, not the current state
  };

  // Format date for input if type is date
  const displayValue = React.useMemo(() => {
    if (value === null) return "";
    if (inputType === "date" && value instanceof Date) {
      return value.toISOString().split("T")[0];
    }
    return value;
  }, [value, inputType]);

  return (
    <input
      type={inputType || "text"}
      className="form-control"
      value={displayValue as string}
      onChange={onChange}
    />
  );
}
