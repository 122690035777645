import React from "react";

export function EditableBooleanCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  return (
    <select
      className="form-select"
      onChange={onChange}
      value={value}
      onBlur={onBlur}
    >
      <option value=""></option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
}
