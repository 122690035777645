import { Row, Table, createColumnHelper } from "@tanstack/react-table";

import { Deployment } from "../../models/Deployment";

import { EditableInputCell } from "./EditableInputCell";
import { EditableSelectCell } from "./EditableSelectCell";
import { EditableBooleanCell } from "./EditableBooleanCell";

const columnHelper = createColumnHelper<Deployment>();

interface RemoveButtonProps {
  row: Row<Deployment>;
  table: Table<Deployment>;
}

interface TableMeta {
  removeRow: (rowIndex: number) => void;
}

function RemoveButton({ row, table }: RemoveButtonProps) {
  return (
    <button
      className="text-danger text-center btn btn-link btn-sm text-decoration-none"
      onClick={() => (table.options.meta as TableMeta).removeRow(row.index)}
    >
      x
    </button>
  );
}

export const DeploymentTableColumns = [
  columnHelper.accessor((row) => row.merchantStatus, {
    id: "deployment_status",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/deployments/deployment_statuses.json"
      />
    ),
    header: () => "Merchant Status",
  }),
  columnHelper.accessor((row) => row.appInDate, {
    id: "app_in_date",
    cell: (info) => <EditableInputCell {...info} inputType="date" />,
    header: () => "App In Date",
  }),
  columnHelper.accessor((row) => row.eSignDate, {
    id: "e_sign_date",
    cell: (info) => <EditableInputCell {...info} inputType="date" />,
    header: () => "E-Sign Date",
  }),
  columnHelper.accessor((row) => row.appStatus, {
    id: "application_status",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/deployments/application_statuses.json"
      />
    ),
    header: () => "App Status",
  }),
  columnHelper.accessor((row) => row.approvedDate, {
    id: "approved_date",
    cell: (info) => <EditableInputCell {...info} inputType="date" />,
    header: () => "Approved Date",
  }),
  columnHelper.accessor((row) => row.deploymentDate, {
    id: "deployment_date",
    cell: (info) => <EditableInputCell {...info} inputType="date" />,
    header: () => "Deployment Date",
  }),
  columnHelper.accessor((row) => row.merchant, {
    id: "merchant_id",
    cell: (props) => (
      <EditableSelectCell
        {...props}
        optionsUrl="/merchants.json"
        allowCreate={true}
        objectName="merchant"
      />
    ),
    header: () => "Merchant",
  }),
  columnHelper.accessor((row) => row.midType, {
    id: "mid_type",
    cell: (info) => (
      <EditableSelectCell {...info} optionsUrl="/deployments/mid_types.json" />
    ),
    header: () => "MID Type",
  }),
  columnHelper.accessor((row) => row.channel, {
    id: "sales_channel_id",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/sales_channels.json"
        allowCreate={true}
        objectName="sales_channel"
      />
    ),
    header: () => "Channel",
  }),
  columnHelper.accessor((row) => row.processor, {
    id: "processor_id",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/processors.json"
        allowCreate={true}
        objectName="processor"
      />
    ),
    header: () => "Processor",
  }),
  columnHelper.accessor((row) => row.platform, {
    id: "platform_id",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/platforms.json"
        allowCreate={true}
        objectName="platform"
      />
    ),
    header: () => "Platform",
  }),
  columnHelper.accessor((row) => row.projectedVolume, {
    id: "projected_volume",
    cell: (info) => <EditableInputCell {...info} inputType="text" />,
    header: () => "Projected Volume",
  }),
  columnHelper.accessor((row) => row.gateway, {
    id: "gateway_id",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/gateways.json"
        allowCreate={true}
        objectName="gateway"
      />
    ),
    header: () => "Gateway",
  }),
  columnHelper.accessor((row) => row.solutionsAndPos, {
    id: "point_of_sale_id",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/point_of_sales.json"
        allowCreate={true}
        objectName="point_of_sale"
      />
    ),
    header: () => "Solutions & POS",
  }),
  columnHelper.accessor((row) => row.ccAchForm, {
    id: "cc_ach_form",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/deployments/cc_ach_forms.json"
      />
    ),
    header: () => "CC/ACH Form",
  }),
  columnHelper.accessor((row) => row.hardwarePaymentTerm, {
    id: "hardware_payment_term",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/deployments/hardware_payment_terms.json"
      />
    ),
    header: () => "HDW Payment Term",
  }),
  columnHelper.accessor((row) => row.deploymentType, {
    id: "deployment_type",
    cell: (info) => (
      <EditableSelectCell
        {...info}
        optionsUrl="/deployments/deployment_types.json"
      />
    ),
    header: () => "Deployment Type",
  }),
  columnHelper.accessor((row) => row.pci, {
    id: "pci_required",
    cell: EditableBooleanCell,
    header: () => "PCI",
  }),
  columnHelper.accessor((row) => row.fixedRate, {
    id: "fixed_rate",
    cell: EditableBooleanCell,
    header: () => "Fixed Rate Y to N's",
  }),
  columnHelper.display({ id: "remove", cell: RemoveButton }),
];
