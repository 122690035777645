import * as React from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table";

import { Deployment } from "../models/Deployment";
import { DeploymentTableColumns } from "./DeploymentTable/Columns";

const DeploymentTable: React.FC = () => {
  const columnResizeMode = "onChange";
  const columnResizeDirection = "ltr";

  const queryClient = useQueryClient();

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const defaultData = React.useMemo(() => [], []);

  // Get Data
  const {
    data: deployments,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["deployments", pagination],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await fetch(
        `/deployments.json?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`,
      );
      if (!response.ok) {
        throw new Error("failed to fetch data");
      }

      const data = await response.json();
      const deployments = data.deployments.map(
        (item: any): Deployment => ({
          id: item.id,
          merchantStatus: item.merchant_status
            ? { id: item.merchant_status, name: item.merchant_status }
            : null,
          appInDate: item.app_in_date ? new Date(item.app_in_date) : null,
          eSignDate: item.e_sign_date ? new Date(item.e_sign_date) : null,
          appStatus: item.app_status
            ? { id: item.app_status, name: item.app_status }
            : null,
          approvedDate: item.approvedDate ? new Date(item.approved_date) : null,
          deploymentDate: item.deployment_date
            ? new Date(item.deployment_date)
            : null,
          merchant: item.merchant,
          midType: item.mid_type
            ? { id: item.mid_type, name: item.mid_type }
            : null,
          channel: item.channel,
          processor: item.processor,
          platform: item.platform,
          projectedVolume: item.projected_volume / 100,
          notes: item.notes,
          gateway: item.gateway,
          solutionsAndPos: item.solutions_and_pos,
          ccAchForm: item.cc_ach_form
            ? { id: item.cc_ach_form, name: item.cc_ach_form }
            : null,
          hardwarePaymentTerm: item.hardware_payment_term
            ? {
                id: item.hardware_payment_term,
                name: item.hardware_payment_term,
              }
            : null,
          deploymentType: item.deployment_type
            ? { id: item.deployment_type, name: item.deployment_type }
            : null,
          pci: item.pci,
          fixedRate: item.fixed_rate,
        }),
      ) as Deployment[];

      return {
        rows: deployments,
        rowCount: data.total,
        pageCount: Math.ceil(data.total / pagination.pageSize),
      };
    },
  });

  // Add mutation for creating new deployment
  const addDeploymentMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch("/deployments.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token":
            document
              .querySelector('[name="csrf-token"]')
              ?.getAttribute("content") || "",
        },
        body: JSON.stringify({
          deployment: {
            deployment_status: null,
            app_in_date: null,
            e_sign_date: null,
            application_status: null,
            approved_date: null,
            deployment_date: null,
            merchant_id: null,
            mid_type: null,
            sales_channel_id: null,
            processor_id: null,
            platform_id: null,
            projected_volume: 0,
            gateway_id: null,
            point_of_sale_id: null,
            cc_ach_form: null,
            hardware_payment_term: null,
            deployment_type: null,
            pci_required: false,
            fixed_rate: false,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create deployment");
      }

      return response.json();
    },
    onSuccess: (newDeployment) => {
      // Transform the new deployment data to match your Deployment type
      const transformedDeployment: Deployment = {
        id: newDeployment.id,
        merchantStatus: newDeployment.merchant_status
          ? {
              id: newDeployment.merchant_status,
              name: newDeployment.merchant_status,
            }
          : null,
        appInDate: newDeployment.app_in_date
          ? new Date(newDeployment.app_in_date)
          : null,
        eSignDate: newDeployment.e_sign_date
          ? new Date(newDeployment.e_sign_date)
          : null,
        appStatus: newDeployment.app_status
          ? { id: newDeployment.app_status, name: newDeployment.app_status }
          : null,
        approvedDate: newDeployment.approved_date
          ? new Date(newDeployment.approved_date)
          : null,
        deploymentDate: newDeployment.deployment_date
          ? new Date(newDeployment.deployment_date)
          : null,
        merchant: newDeployment.merchant,
        midType: newDeployment.mid_type
          ? { id: newDeployment.mid_type, name: newDeployment.mid_type }
          : null,
        channel: newDeployment.channel,
        processor: newDeployment.processor,
        platform: newDeployment.platform,
        projectedVolume: newDeployment.projected_volume / 100,
        notes: newDeployment.notes,
        gateway: newDeployment.gateway,
        solutionsAndPos: newDeployment.solutions_and_pos,
        ccAchForm: newDeployment.cc_ach_form
          ? { id: newDeployment.cc_ach_form, name: newDeployment.cc_ach_form }
          : null,
        hardwarePaymentTerm: newDeployment.hardware_payment_term
          ? {
              id: newDeployment.hardware_payment_term,
              name: newDeployment.hardware_payment_term,
            }
          : null,
        deploymentType: newDeployment.deployment_type
          ? {
              id: newDeployment.deployment_type,
              name: newDeployment.deployment_type,
            }
          : null,
        pci: newDeployment.pci,
        fixedRate: newDeployment.fixed_rate,
      };

      // Update the cache with the new deployment
      queryClient.setQueryData(
        ["deployments", pagination],
        (
          oldData:
            | { rows: Deployment[]; rowCount: number; pageCount: number }
            | undefined,
        ) => {
          if (!oldData)
            return { rows: [transformedDeployment], rowCount: 1, pageCount: 1 };

          return {
            rows: [...oldData.rows, transformedDeployment],
            rowCount: oldData.rowCount + 1,
            pageCount: Math.ceil((oldData.rowCount + 1) / pagination.pageSize),
          };
        },
      );

      // Scroll to the new row
      setTimeout(() => {
        const newRow = document.getElementById(`row-${newDeployment.id}`);
        if (newRow) {
          newRow.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
  });

  const table = useReactTable({
    data: deployments?.rows ?? defaultData,
    columns: DeploymentTableColumns,
    columnResizeMode,
    columnResizeDirection,
    rowCount: deployments?.rowCount,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    getRowId: (row) => row.id.toString(),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: async (rowIndex: number, columnId: string, value: any) => {
        if (!deployments) return;

        const deployment: Deployment = deployments?.rows[rowIndex];

        let newValue = null;
        if (value instanceof Date) {
          newValue = value.toISOString();
        } else if (value !== null && typeof value === "object") {
          newValue = value.id;
        } else {
          newValue = value;
        }

        try {
          const response = await fetch(`/deployments/${deployment.id}.json`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              // If you're using CSRF protection in Rails
              "X-CSRF-Token":
                document
                  .querySelector('[name="csrf-token"]')
                  ?.getAttribute("content") || "",
            },
            body: JSON.stringify({
              deployment: {
                [columnId]: newValue,
              },
            }),
          });

          if (!response.ok) {
            throw new Error("Failed to update deployment");
          }
        } catch (error) {
          console.error("Error updating deployment:", error);
          // Handle error (show toast notification, etc.)
        }
      },
      addRow: () => {
        addDeploymentMutation.mutate();
      },
      removeRow: async (rowIndex: number) => {
        if (!deployments) return;

        const deployment: Deployment = deployments?.rows[rowIndex];

        try {
          const response = await fetch(`/deployments/${deployment.id}.json`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              // If you're using CSRF protection in Rails
              "X-CSRF-Token":
                document
                  .querySelector('[name="csrf-token"]')
                  ?.getAttribute("content") || "",
            },
          });

          if (!response.ok) {
            throw new Error("Failed to delete deployment");
          }

          // Update the cache by removing the deleted deployment
          queryClient.setQueryData(
            ["deployments"],
            (old: Deployment[] = []) => {
              return old.filter((d) => d.id !== deployment.id);
            },
          );
        } catch (error) {
          console.error("Error deleting deployment:", error);
          // Handle error (show toast notification, etc.)
        }
      },
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div
        className="deployments-table-container"
        style={{ direction: table.options.columnResizeDirection }}
      >
        <table
          className="table table-bordered deployments-table"
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    {...{
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          table.options.columnResizeDirection
                        } ${header.column.getIsResizing() ? "isResizing" : ""}`,
                        style: {
                          transform: "",
                        },
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} id={`row-${row.id}`}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    {...{
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="border-t">
            <tr>
              <th colSpan={table.getCenterLeafColumns().length}>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={table.options.meta.addRow}
                >
                  Add New +
                </button>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="d-flex items-center gap-2 mt-2 mb-4">
        <nav>
          <ul className="pagination mb-0">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.firstPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.lastPage()}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>

        <span className="d-flex align-items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
      </div>
    </>
  );
};

export default DeploymentTable;
