import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deployments--merchants"
export default class extends Controller {
  static targets = ["selectMerchant", "newMerchant"];

  connect() {}

  showNewMerchant(event) {
    event.preventDefault();

    this.selectMerchantTarget.classList.add("d-none");
    this.newMerchantTarget.classList.remove("d-none");
  }

  showSelectMerchant(event) {
    event.preventDefault();

    this.selectMerchantTarget.classList.remove("d-none");
    this.newMerchantTarget.classList.add("d-none");
  }
}
