import { Controller } from "@hotwired/stimulus";

import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import DeploymentsTable from "../components/DeploymentsTable";

// Connects to data-controller="deployments"
export default class extends Controller {
  connect() {
    const queryClient = new QueryClient();

    const root = createRoot(this.element);
    root.render(
      <QueryClientProvider client={queryClient}>
        <DeploymentsTable />
      </QueryClientProvider>,
    );
  }
}
