import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import Select, { SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import { OptionType } from "../../models/OptionType";

const reactSelectStyles = {
  control: (base: any) => ({
    ...base,
    border: "none",
    boxShadow: "none",
  }),
};

export function EditableSelectCell({
  getValue,
  row,
  column,
  table,
  optionsUrl,
  allowCreate = false,
  objectName = "",
}) {
  const initialValue = getValue();
  const [value, setValue] = React.useState<OptionType>(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState();

  // Load data from the API endpoint
  const { data: options = [] } = useQuery({
    queryKey: ["selectOptions", optionsUrl],
    queryFn: async () => {
      const response = await fetch(optionsUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch options: ${optionsUrl}`);
      }

      return response.json() as Promise<OptionType[]>;
    },
  });

  // Update selectOptions with loaded data
  React.useEffect(() => {
    const converted = options.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    setSelectOptions(converted);
  }, [options]);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  let selectedOption = null;

  if (!value) {
    selectedOption = { value: "", label: "" };
  } else if (typeof value == "string") {
    selectedOption = {
      value: value,
      label: value,
    };
  } else {
    selectedOption = {
      value: value.id,
      label: value.name,
    };
  }

  const onChange = (newValue) => {
    if (!newValue) {
      const selectedOption = { id: "", name: "" } as OptionType;
      setValue(selectedOption);
      table.options.meta?.updateData(row.index, column.id, selectedOption);
      return;
    }

    const selectedOption = options.find((opt) => opt.id === newValue.value);
    if (selectedOption) {
      setValue(selectedOption);

      table.options.meta?.updateData(row.index, column.id, selectedOption);
    }
  };

  const handleCreate = async (inputValue: string) => {
    setIsLoading(true);

    const response = await fetch(optionsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token":
          document
            .querySelector('[name="csrf-token"]')
            ?.getAttribute("content") || "",
      },
      body: JSON.stringify({
        [objectName]: {
          name: inputValue,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to create ${objectName}`);
    }

    const data = await response.json();

    const newOption = { value: data.id, label: data.name };
    setSelectOptions((prev) => [...prev, newOption]);
    setValue({ id: data.id, name: data.name });
    table.options.meta?.updateData(row.index, column.id, {
      id: data.id,
      name: data.name,
    });

    setIsLoading(false);
  };

  if (allowCreate) {
    return (
      <CreatableSelect
        value={selectedOption}
        onChange={onChange}
        options={selectOptions}
        classNamePrefix="react-select"
        isClearable={true}
        backspaceRemovesValue={true}
        menuPortalTarget={document.querySelector("body")}
        styles={reactSelectStyles}
        onCreateOption={handleCreate}
        isDisabled={isLoading}
        isLoading={isLoading}
      />
    );
  } else {
    return (
      <Select
        value={selectedOption}
        onChange={onChange}
        options={selectOptions}
        classNamePrefix="react-select"
        isClearable={true}
        backspaceRemovesValue={true}
        menuPortalTarget={document.querySelector("body")}
        styles={reactSelectStyles}
      />
    );
  }
}
